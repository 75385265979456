var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"zt-page-content"},[_c('div',{staticClass:"zt-block search-query"},[_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"fl flex tab-menu"},_vm._l((_vm.tabList),function(item,index){return _c('div',{key:index,staticClass:"menu-item",class:{ cur: _vm.tabIndex == index },on:{"click":function($event){return _vm.tab(index)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),0),_c('div',{staticClass:"fr"},[_c('el-row',{attrs:{"type":"flex","gutter":24}})],1)])]),_c('div',{staticClass:"zt-block",staticStyle:{"height":"calc(100% - 144px)","overflow":"hidden","margin-top":"0"}},[_c('div',{staticClass:"list",staticStyle:{"height":"100%","overflow":"auto"}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.list}},[_c('el-table-column',{attrs:{"prop":"title","label":"标题"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"box font14-grey"},[_vm._v(_vm._s(scope.row.title))])]}}])}),_c('el-table-column',{attrs:{"prop":"type","label":"需求种类"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"box font14-grey1"},[(scope.row.type && scope.row.type == 'peitao')?_c('div',[_vm._v(" 加工需求 ")]):_vm._e(),(scope.row.type && scope.row.type == 'gaikuang')?_c('div',[_vm._v(" 设计需求 ")]):_vm._e(),(scope.row.type && scope.row.type == 'dayang')?_c('div',[_vm._v(" 打样 ")]):_vm._e()])]}}])}),_c('el-table-column',{attrs:{"prop":"createdAt","label":"类目"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
                scope.row[scope.row.type] && scope.row[scope.row.type].cat
              )?_c('div',{staticClass:"box font14-grey1"},[_vm._v(" "+_vm._s(scope.row[scope.row.type].cat.name)+" ")]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"createdAt","min-width":"320px","max-width":"450px","label":"需求信息"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"box price font14-grey1 offer-info"},[_c('div',{staticClass:"offer-info-item flex"},[_c('div',[_vm._v("出价预算")]),(scope.row[scope.row.type].prices)?_c('div',[(scope.row[scope.row.type].prices[0])?_c('span',[_vm._v("￥"+_vm._s(_vm._f("priceFilt")(scope.row[scope.row.type].prices[0])))]):_vm._e(),(scope.row[scope.row.type].prices[1])?_c('span',[_vm._v("~"+_vm._s(_vm._f("priceFilt")(scope.row[scope.row.type].prices[1])))]):_vm._e()]):(scope.row.offers && scope.row.offers.length > 0)?_c('div',[_vm._v(" ￥"+_vm._s(_vm._f("priceFilt")(scope.row.offers[0][scope.row.type].price))+" ")]):_vm._e()]),(['peitao', 'gaikuang'].includes(scope.row.type))?_c('div',{staticClass:"offer-info-item flex"},[_c('div',[_vm._v("设计类型")]),(
                    scope.row.type == 'gaikuang' &&
                    scope.row[scope.row.type] &&
                    scope.row[scope.row.type].fileTypes.length > 0
                  )?_c('div',_vm._l((scope.row[scope.row.type]
                      .fileTypes),function(item1,idx1){return _c('span',{key:idx1,staticStyle:{"display":"inline-block","margin-right":"10px"}},[(item1 == '3d')?_c('span',[_vm._v("3d渲染图")]):_vm._e(),(item1 == 'material')?_c('span',[_vm._v("电商素材")]):_vm._e(),(item1 == 'size')?_c('span',[_vm._v("尺寸图")]):_vm._e()])}),0):_vm._e()]):_vm._e(),(scope.row.type == 'dayang')?_c('div',{staticClass:"offer-info-item flex"},[_c('div',{staticStyle:{"color":"#b3b3b3"}},[_vm._v("验收方式")]),_c('div',[(
                      scope.row[scope.row.type] &&
                      scope.row[scope.row.type].checkType == 1
                    )?_c('span',[_vm._v("样品寄送验收")]):_vm._e(),(
                      scope.row[scope.row.type] &&
                      scope.row[scope.row.type].checkType == 2
                    )?_c('span',[_vm._v("视频验收")]):_vm._e()])]):_vm._e(),_c('div',{staticStyle:{"color":"#b3b3b3"}},[(['peitao', 'dayang'].includes(scope.row.type))?_c('div',[_vm._v(" 需求量 ")]):_vm._e()]),_c('div',[(
                    scope.row.type == 'peitao' &&
                    scope.row[scope.row.type] &&
                    scope.row[scope.row.type].count
                  )?_c('div',[_vm._v(" "+_vm._s(scope.row[scope.row.type].count)+" "),_c('span',{staticClass:"font14-grey1"},[_vm._v(" 个 "),(
                        scope.row[scope.row.type] &&
                        scope.row[scope.row.type].type == 2
                      )?_c('span',[_vm._v("/月")]):_vm._e()])]):_vm._e(),(
                    scope.row.type == 'dayang' &&
                    scope.row[scope.row.type] &&
                    scope.row[scope.row.type].count
                  )?_c('div',[_vm._v(" "+_vm._s(scope.row[scope.row.type].count)+" "),_c('span',{staticClass:"font14-grey1"},[_vm._v("个")])]):_vm._e()]),_c('div',{staticClass:"offer-info-item flex"},[(scope.row.type == 'dayang')?_c('div',[_vm._v("验收时间")]):_vm._e(),(scope.row.type == 'gaikuang')?_c('div',[_vm._v("交货时间")]):_vm._e(),(scope.row.type == 'peitao')?_c('div',[_vm._v("递交时间")]):_vm._e(),(scope.row.type == 'dayang')?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(scope.row[scope.row.type].checkDate,"YYYY/MM/DD"))+" ")]):_c('div',[(scope.row.offers)?_c('div',[_vm._v(" "+_vm._s(_vm._f("moment")(scope.row.offers[0][scope.row.type].giveDate,"YYYY/MM/DD"))+" ")]):_c('div',[_vm._v(_vm._s(_vm._f("moment")(scope.row[scope.row.type].giveDate,"YYYY/MM/DD")))])])])])]}}])}),_c('el-table-column',{attrs:{"prop":"factory_addr","width":"150px","label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"box"},[(scope.row.state == 4)?_c('div',[(scope.row.type === 'dayang')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goto('/want/sellerOrder')}}},[_vm._v("查看订单")]):_vm._e(),(scope.row.type === 'gaikuang')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goto('/desgin/order/demandCustomize')}}},[_vm._v("查看订单")]):_vm._e(),(scope.row.type === 'peitao')?_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goto('/want/sellerOrder')}}},[_vm._v("查看订单")]):_vm._e()],1):_c('div',[(scope.row.offers && scope.row.offers.length > 0)?_c('div',[_c('span',{staticStyle:{"margin-right":"10px"}},[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goto(
                          '/want/joinWant/pipe/' + scope.row.id + '?type=1'
                        )}}},[_vm._v("已应答")])],1),_c('span',[_vm._v(_vm._s(_vm._f("moment")(scope.row.offers[0][scope.row.type].createdAt,"MM-DD")))])]):_c('div',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.goto('/want/joinWant/pipe/' + scope.row.id + '?type=0')}}},[_vm._v("查看详情")])],1)])])]}}])})],1)],1)]),_c('div',{staticStyle:{"padding":"7px"}},[_c('el-pagination',{attrs:{"background":"","layout":"prev, pager, next,total","current-page":_vm.queryOpt.page.pageNumber,"page-size":_vm.queryOpt.page.pageCount,"total":_vm.total},on:{"update:currentPage":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)},"update:current-page":function($event){return _vm.$set(_vm.queryOpt.page, "pageNumber", $event)}}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }