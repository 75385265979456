<template>
  <!-- 应答生意(待答复和已答复的列表) -->
  <!-- 我的需求应答 -->
  <div class="zt-page-content">
    <div class="zt-block search-query">
      <div class="clearfix">
        <div class="fl flex tab-menu">
          <div
            v-for="(item, index) in tabList"
            :key="index"
            @click="tab(index)"
            class="menu-item"
            :class="{ cur: tabIndex == index }"
          >
            {{ item.name }}
          </div>
        </div>
        <div class="fr">
          <el-row type="flex" :gutter="24">
            <!-- <el-col>
              <el-row>
                <el-col>
                  <el-row type="flex" align="middle">
                    <el-col :span="12" class="label">需求类型</el-col>
                    <el-col>
                      <el-select v-model="demandType" @clear="clearContent">
                        <el-option
                          v-for="item in demandTypeOption"
                          :key="item.optionCode"
                          :label="item.optionValue"
                          :value="item.optionCode"
                        ></el-option>
                      </el-select>
                    </el-col>
                  </el-row>
                </el-col>
              </el-row>
            </el-col> -->
            <!-- <el-col class="search-content">
              <el-input
                placeholder="请输入搜索关键字"
                suffix-icon="el-icon-search"
                @change="getKeyWords"
                v-model="keywords"()
              ></el-input>
            </el-col>-->
          </el-row>
        </div>
      </div>
    </div>

    <div
      class="zt-block"
      style="height: calc(100% - 144px); overflow: hidden; margin-top: 0"
    >
      <div class="list" style="height: 100%; overflow: auto">
        <el-table :data="list" style="width: 100%">
          <el-table-column prop="title" label="标题">
            <template slot-scope="scope">
              <div class="box font14-grey">{{ scope.row.title }}</div>
            </template>
          </el-table-column>
          <el-table-column prop="type" label="需求种类">
            <template slot-scope="scope">
              <div class="box font14-grey1">
                <div v-if="scope.row.type && scope.row.type == 'peitao'">
                  加工需求
                </div>
                <div v-if="scope.row.type && scope.row.type == 'gaikuang'">
                  设计需求
                </div>
                <div v-if="scope.row.type && scope.row.type == 'dayang'">
                  打样
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="createdAt" label="类目">
            <template slot-scope="scope">
              <div
                class="box font14-grey1"
                v-if="
                  scope.row[scope.row.type] && scope.row[scope.row.type].cat
                "
              >
                {{ scope.row[scope.row.type].cat.name }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="createdAt"
            min-width="320px"
            max-width="450px"
            label="需求信息"
          >
            <template slot-scope="scope">
              <div class="box price font14-grey1 offer-info">
                <div class="offer-info-item flex">
                  <div>出价预算</div>
                  <div v-if="scope.row[scope.row.type].prices">
                    <span v-if="scope.row[scope.row.type].prices[0]"
                      >￥{{
                        scope.row[scope.row.type].prices[0] | priceFilt
                      }}</span
                    >
                    <span v-if="scope.row[scope.row.type].prices[1]"
                      >~{{
                        scope.row[scope.row.type].prices[1] | priceFilt
                      }}</span
                    >
                  </div>
                  <div
                    v-else-if="scope.row.offers && scope.row.offers.length > 0"
                  >
                    ￥{{
                      scope.row.offers[0][scope.row.type].price | priceFilt
                    }}
                  </div>
                </div>
                <div
                  class="offer-info-item flex"
                  v-if="['peitao', 'gaikuang'].includes(scope.row.type)"
                >
                  <div>设计类型</div>
                  <div
                    v-if="
                      scope.row.type == 'gaikuang' &&
                      scope.row[scope.row.type] &&
                      scope.row[scope.row.type].fileTypes.length > 0
                    "
                  >
                    <span
                      style="display: inline-block; margin-right: 10px"
                      v-for="(item1, idx1) in scope.row[scope.row.type]
                        .fileTypes"
                      :key="idx1"
                    >
                      <span v-if="item1 == '3d'">3d渲染图</span>
                      <span v-if="item1 == 'material'">电商素材</span>
                      <span v-if="item1 == 'size'">尺寸图</span>
                    </span>
                  </div>
                  <!-- <div
                    v-if="scope.row.offers && scope.row.offers.length>0"
                  >{{scope.row.offers[0][scope.row.type]. designType}}</div>-->
                </div>
                <div
                  class="offer-info-item flex"
                  v-if="scope.row.type == 'dayang'"
                >
                  <div style="color: #b3b3b3">验收方式</div>
                  <div>
                    <span
                      v-if="
                        scope.row[scope.row.type] &&
                        scope.row[scope.row.type].checkType == 1
                      "
                      >样品寄送验收</span
                    >
                    <span
                      v-if="
                        scope.row[scope.row.type] &&
                        scope.row[scope.row.type].checkType == 2
                      "
                      >视频验收</span
                    >
                  </div>
                </div>
                <div style="color: #b3b3b3">
                  <div v-if="['peitao', 'dayang'].includes(scope.row.type)">
                    需求量
                  </div>
                </div>
                <div>
                  <div
                    v-if="
                      scope.row.type == 'peitao' &&
                      scope.row[scope.row.type] &&
                      scope.row[scope.row.type].count
                    "
                  >
                    {{ scope.row[scope.row.type].count }}
                    <span class="font14-grey1">
                      个
                      <span
                        v-if="
                          scope.row[scope.row.type] &&
                          scope.row[scope.row.type].type == 2
                        "
                        >/月</span
                      >
                    </span>
                  </div>
                  <div
                    v-if="
                      scope.row.type == 'dayang' &&
                      scope.row[scope.row.type] &&
                      scope.row[scope.row.type].count
                    "
                  >
                    {{ scope.row[scope.row.type].count }}
                    <span class="font14-grey1">个</span>
                  </div>
                </div>
                <div class="offer-info-item flex">
                  <div v-if="scope.row.type == 'dayang'">验收时间</div>
                  <div v-if="scope.row.type == 'gaikuang'">交货时间</div>
                  <div v-if="scope.row.type == 'peitao'">递交时间</div>
                  <div v-if="scope.row.type == 'dayang'">
                    {{
                      scope.row[scope.row.type].checkDate | moment("YYYY/MM/DD")
                    }}
                  </div>
                  <div v-else>
                    <div v-if="scope.row.offers">
                      {{
                        scope.row.offers[0][scope.row.type].giveDate
                          | moment("YYYY/MM/DD")
                      }}
                    </div>
                    <div v-else>{{
                        scope.row[scope.row.type].giveDate
                          | moment("YYYY/MM/DD")
                      }}</div>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="factory_addr" width="150px" label="操作">
            <template slot-scope="scope">
              <div class="box">
                <!-- 已生成订单显示查看订单,未生成订单(不管应答与否)显示查看详情(应答过显示已应答) -->
                <div v-if="scope.row.state == 4">
                  <el-button
                    v-if="scope.row.type === 'dayang'"
                    @click="goto('/want/sellerOrder')"
                    type="text"
                    >查看订单</el-button
                  >
                  <el-button
                    v-if="scope.row.type === 'gaikuang'"
                    @click="goto('/desgin/order/demandCustomize')"
                    type="text"
                    >查看订单</el-button
                  >
                  <el-button
                    v-if="scope.row.type === 'peitao'"
                    @click="goto('/want/sellerOrder')"
                    type="text"
                    >查看订单</el-button
                  >
                </div>
                <div v-else>
                  <div v-if="scope.row.offers && scope.row.offers.length > 0">
                    <span style="margin-right: 10px">
                      <el-button
                        @click="
                          goto(
                            '/want/joinWant/pipe/' + scope.row.id + '?type=1'
                          )
                        "
                        type="text"
                        >已应答</el-button
                      >
                    </span>
                    <span>{{
                      scope.row.offers[0][scope.row.type].createdAt
                        | moment("MM-DD")
                    }}</span>
                  </div>
                  <div v-else>
                    <el-button
                      @click="
                        goto('/want/joinWant/pipe/' + scope.row.id + '?type=0')
                      "
                      type="text"
                      >查看详情</el-button
                    >
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>

    <div style="padding: 7px">
      <el-pagination
        background
        layout="prev, pager, next,total"
        :current-page.sync="queryOpt.page.pageNumber"
        :page-size="queryOpt.page.pageCount"
        :total="total"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import localForage from "localforage";
import { IMG_URL_PRE } from "@/config";
import { getRequirePlistList } from "@/service/want";
import { demandTypeOption } from "@/utils/constant";
export default {
  components: {},
  data() {
    return {
      tabList: [{ name: "待答复的生意" }, { name: "已答复的生意" }],
      tabIndex: 0,
      queryOpt: {
        where: {
          types: null,
          offer: undefined,
          offerCompIdsNotIn: null,
          offerCompIds: null,
          states: [2, 3],
        },
        page: {
          pageNumber: 1,
          pageCount: 10,
        },
      },
      demandType: "全部",
      demandTypeOption: demandTypeOption,
      keywords: "",
      total: 0,
      list: [],
      comp: null,
    };
  },
  watch: {
    queryOpt: {
      handler() {
        this.debounceRefreshItems();
      },
      deep: true,
    },
    demandType: {
      handler(val) {
        this.queryOpt.where.types = [val];
      },
    },
  },
  created() {
    this.comp = this.$store.state.loginUser.comp;
    // 工厂
    // 待答复types:peitao states: [2,3] 已答复types:peitao states: [3,4]
    // 设计公司
    // 待答复types:guaikuang states: [2,3] 已答复types:guaikuang states: [3,4]
    // 打样中心
    // 待答复types:dayang states: [2,3] 已答复types:dayang states: [3,4]
    if (this.comp && this.comp.comp_type) {
      switch (this.comp.comp_type) {
        case 1:
          this.queryOpt.where.types = ["peitao"];
          this.demandTypeOption = demandTypeOption.filter(
            (item) => item.optionCode === "peitao"
          );
          break;
        case 2:
          this.queryOpt.where.types = ["gaikuang"];
          this.demandTypeOption = demandTypeOption.filter(
            (item) => item.optionCode == "gaikuang"
          );
          break;
        case 4:
          this.queryOpt.where.types = ["dayang"];
          this.demandTypeOption = demandTypeOption.filter(
            (item) => item.optionCode === "dayang"
          );
          break;
      }
    }
    this.queryOpt.where.offerCompIdsNotIn = [this.comp.id];
    this.debounceRefreshItems = _.debounce(this.refreshItems, 500);
  },
  mounted() {},
  methods: {
    goOrder() {
      if (this.item.type === "dayang") {
        this.goto("/want/sellerOrder");
      } else {
        this.goto("/want/sellerOrder");
      }
    },
    clearContent() {
      this.demandType = ["gaikuang", "dayang"];
      this.refreshItems();
    },
    getKeyWords(val) {
      this.queryOpt.keywords = val;
    },
    goto(link) {
      this.$router.push(link);
    },
    // 选项卡
    tab(index) {
      this.tabIndex = index;
      if (index == 0) {
        this.queryOpt.where.offer = undefined;
        this.queryOpt.where.offerCompIdsNotIn = [this.comp.id];
        this.queryOpt.where.offerCompIds = null;
        this.queryOpt.where.states = [2, 3];
      }
      if (index == 1) {
        this.queryOpt.where.offerCompIds = [this.comp.id];
        this.queryOpt.where.offerCompIdsNotIn = null;
        this.queryOpt.where.offer = {
          compIds: [this.comp.id],
        };
        this.queryOpt.where.states = [3, 4];
      }
    },
    refreshItems() {
      console.log("refreshItems");
      this.list = [];
      getRequirePlistList(this.queryOpt)
        .then((rst) => {
          if (rst.rows && rst.rows.length > 0) {
            this.total = rst.count;
            this.list = rst.rows;
          } else {
            this.total = 0;
            this.list = [];
          }
          console.log(this.list);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped src="../style.css"></style>
<style scoped>
/*----选项卡----*/
.tab_menu {
  margin: 10px 20px;
}
.tab_menu .menu_item {
  height: 58px;
  line-height: 58px;
  margin: 0 40px;
  cursor: pointer;
  font-size: 16px;
  color: #4d4d4d;
}
.tab_menu .menu_item:first-child {
  margin-right: 1px solid #e6e6e6;
}
.tab_menu .menu_item.cur {
  color: #5074ee;
}

.list > .list-item {
  padding: 10px;
  background: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 2px 4px 0px rgba(209, 217, 226, 1);
}
.list > .list-item:last-child {
  margin-bottom: 0;
}
.list > .list-item > div {
  min-width: 180px;
  min-height: 120px;
  padding: 0 40px;
  border-right: 1px solid #e6e6e6;
}
.list > .list-item > div:first-child {
  padding-left: 0;
}
.list > .list-item > div:last-child {
  border-right: none;
}
.bg_img {
  width: 120px;
  height: 120px;
}
.list > .list-item .list-item-tr > div:last-child {
  font-size: 18px;
  color: #3b3b3b;
  line-height: 25px;
  margin-top: 10px;
}
.list >>> .el-table td .cell {
  border-right: 1px solid #ebeef5;
}
.box {
  height: 105px;
}
.price > div > div:nth-child(1) {
  display: inline-block;
  vertical-align: top;
  width: 48px;
  text-align: right;
  font-size: 12px;
  color: #b3b3b3;
  margin-right: 10px;
  line-height: 25px;
}
.price > div > div:nth-child(2) {
  font-size: 18px;
  font-weight: bold;
  color: #3b3b3b;
  line-height: 25px;
}
</style>
<style lang="less" scoped>
.offer-info {
  &-item {
    text-align: left;
  }
}
</style>